.App {
  text-align: center;
  background-color: white;
}
body,html,#root{
  background-color: white;
  color: black!important;
}
main{
  overflow-x: unset;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.carousel{
width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body,html,input{
  color: black!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

amp-accordion {
  width: 100%;
}

amp-accordion section h4 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-bottom: .5rem !important;
}

amp-accordion section table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

amp-accordion section .bg-tertiary {
  background-color: #f0f3f5;
}

amp-accordion section .bg-darken-1 {
  background-color: rgba(0,0,0,.0625);
}

amp-accordion section .table-light td,
amp-accordion section .table-light th {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,.125);
}

amp-accordion section th,
amp-accordion section td {
  padding: .25rem 1rem;
  line-height: inherit;
}

amp-accordion section .pull-right {
  float: right;
}

.i-amphtml-layout-size-defined>[fallback], 
.i-amphtml-layout-size-defined>[placeholder] {
  position: absolute!important;
  top: 0!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  z-index: 1;
}

amp-iframe amp-img.i-amphtml-element.i-amphtml-built {
  position: absolute!important;
  top: 0!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  z-index: 1;
}






.MuiButton-label {
  text-transform: capitalize!important;
}


.flex {
    display: flex;
    justify-content: space-between;
}

.clear {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
}

a:hover{
  text-decoration: none!important;
}
html.i-amphtml-fie>body, html.i-amphtml-singledoc>body{
  padding:0px!important;
}
ul{
padding: 0px!important;
}

.MuiCheckbox-colorPrimary.Mui-checked,.MuiSwitch-colorPrimary.Mui-checked,.MuiSwitch-track{
color:#b81b22!important;

}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
  background-color:#b81b22!important;
}
  
.MuiButton-root.Mui-disabled{
  color: #ffffff69!important;
}
.MuiButton-root{
  color: #ffffff!important;
}