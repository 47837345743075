
/* @font-face {
  font-family: NanumGothicBBold;
  src: url(./fonts/AvenirLTStd-Black.otf);
}
@font-face {
  font-family: NanumGothicBook;
  src: url(./fonts/AvenirLTStd-Book.otf);
} */
body {
  margin: 0;
  background-color: white;
  font-family: "arial"!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,div,a,button{
  font-family: "arial"!important;
  text-decoration: none!important;
}
h1,h2,h3,h5{
  font-family: "arial"!important;
}